// PrivacyPolicy.js
import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div style={{ color: "#fff", backgroundColor: "#1D1D1D" }}>
      {/* Header 部分 */}
      <header className="w-full bg-[#1D1D1D] px-4 py-3 flex justify-between items-center fixed top-0 z-50">
        {/* 增加 `z-50` 和 `top-0` */}
        <h1 className="text-lg font-bold">LKKIM</h1>
        <nav className="flex justify-end font-[400]">
          <Link to="/" className="text-gray-400">
            Home
          </Link>
        </nav>
      </header>

      {/* 隐私政策内容 */}
      <div className="pt-20 px-6 md:px-10 lg:px-20 py-10   mx-auto max-w-screen-lg">
        <h1 className="text-3xl font-semibold mb-6">Privacy Policy</h1>
        <p className="mb-4 text-gray-300">
          LKKIM ("we", "our", "us") is committed to protecting your privacy.
          This Privacy Policy explains how we collect, use, disclose, and
          safeguard your information when you use our digital cold wallet
          services and mobile application. Please read this policy carefully to
          understand our views and practices regarding your personal data.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-2">
          1. Information We Collect
        </h2>
        <p className="mb-4 text-gray-300">
          We collect various types of information in connection with the
          services we provide, including:
        </p>
        <ul className="list-disc ml-8 mb-4 text-gray-300">
          <li>
            <strong>Personal Identification Information</strong>: Name, email
            address, contact details.
          </li>
          <li>
            <strong>Usage Data</strong>: Information on how you interact with
            our application, including device information, IP address, and log
            information.
          </li>
          <li>
            <strong>Transaction Data</strong>: Information about the
            transactions you perform through LKKIM’s cold wallet application.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-2">
          2. How We Use Your Information
        </h2>
        <p className="mb-4 text-gray-300">
          The information we collect is used for the following purposes:
        </p>
        <ul className="list-disc ml-8 mb-4 text-gray-300">
          <li>
            To provide and maintain our services, ensuring the secure management
            of your cryptocurrency assets.
          </li>
          <li>
            To improve our app performance, analyze trends, and enhance user
            experience.
          </li>
          <li>To respond to your requests, feedback, or support inquiries.</li>
          <li>
            To ensure compliance with regulatory requirements and prevent
            fraudulent or illegal activities.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-2">
          3. Information Sharing and Disclosure
        </h2>
        <p className="mb-4 text-gray-300">
          We do not share your personal data with third parties, except in the
          following cases:
        </p>
        <ul className="list-disc ml-8 mb-4 text-gray-300">
          <li>When we have your explicit consent to share the information.</li>
          <li>
            To comply with legal obligations, such as responding to court orders
            or regulatory requirements.
          </li>
          <li>
            To protect and defend our rights, privacy, safety, or property, and
            those of our users and affiliates.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-2">4. Data Security</h2>
        <p className="mb-4 text-gray-300">
          We use advanced security measures, including bank-grade encryption and
          secure chip technology, to protect your personal data against
          unauthorized access and cyber threats. While we strive to use
          commercially acceptable means to protect your data, we cannot
          guarantee its absolute security.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-2">
          5. Your Rights and Choices
        </h2>
        <p className="mb-4 text-gray-300">
          You have the following rights regarding your data:
        </p>
        <ul className="list-disc ml-8 mb-4 text-gray-300">
          <li>
            <strong>Access and Correction</strong>: You may access and update
            your personal information directly in the application or contact us
            for assistance.
          </li>
          <li>
            <strong>Data Portability</strong>: You may request a copy of your
            personal data in a structured, machine-readable format.
          </li>
          <li>
            <strong>Data Erasure</strong>: You can request that we delete your
            personal data, subject to regulatory obligations.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-2">6. Data Retention</h2>
        <p className="mb-4 text-gray-300">
          We retain your personal data only as long as necessary to fulfill the
          purposes for which it was collected and to comply with legal
          obligations. Once your data is no longer required, we will delete it
          securely.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-2">
          7. Changes to This Privacy Policy
        </h2>
        <p className="mb-4 text-gray-300">
          We may update our Privacy Policy periodically to reflect changes in
          our practices, technologies, or legal requirements. We will notify you
          of any significant changes through our application or via email.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-2">8. Contact Us</h2>
        <p className="mb-4 text-gray-300">
          If you have questions or concerns about this Privacy Policy or wish to
          exercise your data rights, please contact us at:
        </p>
        <p className="text-gray-300">
          <strong>Email</strong>: likkimswiss@gmail.com
          <br />
          <strong>Address</strong>: LKKIM Headquarters, Zug, Switzerland
        </p>
      </div>

      {/* Footer 部分 */}
      <div className="w-full bg-[#121212] py-6 flex flex-col items-center space-y-4 text-gray-400">
        <div className="text-sm">
          <Link to="/" className="text-gray-400">
            Home
          </Link>
        </div>
        <p className="text-xs">&copy; 2024 LKKIM. All rights reserved.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
